import { createTheme, alpha } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { ButtonProps, ChipProps, TabProps } from '@mui/material';
import { formatNumber } from './util';
import {
  primaryKey,
  tintKey,
  lightKey,
  shadeKey,
  vdxBlue,
  vdxTeal,
  vdxRed,
  vdxYellow,
  vdxGreen,
  vdxPink,
  vdxPurple,
  vdxGray,
} from './colors';

// breakpoint boolean names to use with MUI's <Hidden> component (must end in Down)
export type HiddenBreakpoints =
  | 'xsDown'
  | 'smDown'
  | 'mdDown'
  | 'lgDown'
  | 'xlDown';

const themeWithPalette = createTheme({
  palette: {
    white: {
      main: '#FFF',
      light: '#FFF',
    },
    primary: {
      main: vdxBlue[primaryKey],
      light: vdxBlue[lightKey],
      dark: vdxBlue[1100],
      contrastText: '#fff',
    },
    info: {
      main: vdxTeal[primaryKey],
      light: vdxTeal[lightKey],
      dark: vdxTeal[shadeKey],
      contrastText: '#fff',
    },
    warning: {
      main: vdxYellow[primaryKey],
      light: vdxYellow[lightKey],
      dark: vdxYellow[shadeKey],
      contrastText: '#fff',
    },
    error: {
      main: vdxRed[primaryKey],
      light: vdxRed[lightKey],
      dark: vdxRed[1100],
      contrastText: '#fff',
    },
    success: {
      main: vdxGreen[primaryKey],
      light: vdxGreen[lightKey],
      dark: vdxGreen[1100],
      contrastText: '#fff',
    },
    grey: {
      main: vdxGray[primaryKey],
      light: vdxGray[lightKey],
      dark: vdxGray[shadeKey],
      borderGrey: '#7A7A82',
      contrastText: '#fff',
    },
    pink: {
      main: vdxPink[primaryKey],
      light: vdxPink[lightKey],
      dark: vdxPink[shadeKey],
      contrastText: '#fff',
    },
    purple: {
      main: vdxPurple[primaryKey],
      light: vdxPurple[lightKey],
      dark: vdxPurple[shadeKey],
      contrastText: '#fff',
    },
    lightblue: {
      main: vdxBlue[200],
      light: vdxBlue[100],
      dark: vdxBlue[300],
      contrastText: vdxBlue[primaryKey],
    },
  },
});

export const shadows = {
  none: 'none',
  1: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  2: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  3: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  4: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  5: '0px 16px 32px rgba(0, 0, 0, 0.1)',
  6: '0px 32px 64px rgba(0, 0, 0, 0.1)',
  7: '0px 64px 128px rgba(0, 0, 0, 0.1)',
  8: '0px 128px 256px rgba(0, 0, 0, 0.1)',
};

// colors to be used in avatars (MUI Avatar)
export const avatarColors = [
  '#31258C',
  '#5240E4',
  '#4666EB',
  '#777CEA',
  '#53CAEE',
  '#52BF58',
  '#88E17D',
  '#C9F8CE',
];

// colors to be used in graphs and visualizations
export const visualizationColors = [
  '#553FED',
  '#AE28BF',
  '#E32D79',
  '#EB6A3F',
  '#E09F1B',
  '#99BF49',
  '#36A3C7',
];

export const defaultStyles: {
  obscureBackdropFilter: CSSProperties['filter'];
  buttonBorderRadius: CSSProperties['borderRadius'];
  borderRadius: CSSProperties['borderRadius'];
  boxShadow: CSSProperties['boxShadow'];
} = {
  buttonBorderRadius: '50vh',
  borderRadius: '4px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
  obscureBackdropFilter: 'brightness(.7) blur(1px)',
};

export const customBreakpoints = {
  lgPlus: 1400,
  xxl: 1920,
};

export const borderRadii = {
  none: '0px',
  sm: '0.125rem',
  DEFAULT: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.5rem',
  full: '9999px',
};

export const theme = createTheme(themeWithPalette, {
  spacing: (value: number) => value * 4,
  vdxColors: {
    vdxGray,
    vdxTeal,
    vdxRed,
    vdxYellow,
    vdxGreen,
    vdxPink,
    vdxPurple,
    vdxBlue,
  },
  borderRadii,
  typography: {
    fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    button: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
      textTransform: 'none',
    },
    h1: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
      fontWeight: 500,
      color: 'rgba(82, 95, 127)',
    },
    h2: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
      fontWeight: 500,
    },
    h3: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
      fontWeight: 500,
    },
    h4: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
      fontWeight: 600,
    },
    body1: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
    body2: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
    caption: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
    h5: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
      '@media (max-width:768px)': {
        // mobile hack to reduce lcp
        fontSize: '1rem !important',
      },
    },
    h6: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
    overline: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
    subtitle1: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
    subtitle2: {
      fontFamily: ['Barlow', 'Helvetica', 'Arial', '"sans-serif"'].join(','),
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        sx: {
          textDecoration: 'none',
          '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },
    // custom breakpoints for Container
    MuiContainer: {
      styleOverrides: {
        maxWidthLgPlus: {
          maxWidth: `${customBreakpoints.lgPlus}px`,
          [`@media (min-width:${customBreakpoints.lgPlus}px)`]: {
            maxWidth: `${customBreakpoints.lgPlus}px`,
          },
        },
        maxWidthXxl: {
          maxWidth: `${customBreakpoints.xxl}px`,
          [`@media (min-width:${customBreakpoints.xxl}px)`]: {
            maxWidth: `${customBreakpoints.xxl}px`,
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-fontSizeXsmall': {
            fontSize: '0.25rem',
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          bgcolor: 'primary.main',
          color: themeWithPalette.palette.common.white,
          paddingTop: 3,
          paddingBottom: 3,
          fontSize: themeWithPalette.typography.body1.fontSize,
          fontWeight: themeWithPalette.typography.body1.fontWeight,
        },
      },
    },
    // style the tooltip to match bootstrap's
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: themeWithPalette.palette.common.black,
          opacity: '0.9 !important',
        },
        tooltip: {
          fontSize: '0.875rem',
          backgroundColor: themeWithPalette.palette.common.black,
          opacity: '0.9 !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }: CustomChipProps) => ({
          borderRadius: defaultStyles.buttonBorderRadius,
          margin: themeWithPalette.spacing(0.5),
          ...(ownerState.variant === 'filled'
            ? { border: '1px solid transparent !important' }
            : {}),
        }),
        label: {
          paddingLeft: themeWithPalette.spacing(1),
          paddingRight: themeWithPalette.spacing(1),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: 'auto', // get rid of the weird margin on DataGrid footer text
        },
        displayedRows: {
          margin: 'auto', // get rid of the weird margin on DataGrid footer text
        },
      },
      defaultProps: {
        labelDisplayedRows: ({
          from,
          to,
          count,
        }: {
          from: number;
          to: number;
          count: number;
        }) => `${from}-${to} of ${formatNumber(count)}`,
      },
    },
    MuiDataGrid: {
      defaultProps: {
        sx: {
          '--DataGrid-overlayHeight': '300px', // default height of the overlay
        },
        ignoreDiacritics: true, // é and e should be the same when searching
      },
      styleOverrides: {
        root: {
          border: 'none',
          width: '100%',
          height: '100%',
        },
        cell: {
          outline: 'none',
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
        iconButtonContainer: {
          paddingLeft: themeWithPalette.spacing(0.5), // a little space between sort button and column header title
        },
        columnHeader: {
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: vdxGray[tintKey],
        },
      },
      defaultProps: {
        textColor: 'inherit',
        indicatorColor: 'secondary',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }: CustomTabProps) => ({
          paddingLeft: 24,
          paddingRight: 24,
          fontSize: 20,
          ...(ownerState.selected ? { backgroundColor: '#ffffff' } : {}),
        }),
      },
    },
    MuiButton: {
      defaultStyles: {
        whiteSpace: 'nowrap',
      },
      styleOverrides: {
        root: ({
          ownerState: { color, disabled, variant },
        }: CustomButtonProps) => {
          const inherit = !color || color === 'inherit';
          const palette = inherit ? null : themeWithPalette.palette[color];

          const style = disabled
            ? {}
            : {
                color: palette == null ? 'inherit' : palette.contrastText,
                backgroundColor: palette == null ? 'inherit' : palette.main,
                '&:hover': {
                  backgroundColor: palette == null ? 'inherit' : palette.dark,
                },
              };

          if (variant === 'rounded') {
            return {
              ...style,
              borderRadius: defaultStyles.buttonBorderRadius,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.divider,
              backgroundColor: disabled
                ? theme.palette.common.white
                : palette == null
                ? 'inherit'
                : palette.main,
              '&:hover': {
                backgroundColor: disabled
                  ? theme.palette.common.white
                  : palette == null
                  ? 'inherit'
                  : palette.dark,
              },
            };
          }

          if (variant === 'roundedOutlined') {
            return {
              ...style,
              color: palette == null ? 'inherit' : palette.main,
              borderRadius: defaultStyles.buttonBorderRadius,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: theme.palette.divider,
              backgroundColor: theme.palette.common.white,
              '&:hover': {
                backgroundColor: alpha(
                  palette == null ? 'inherit' : palette.main,
                  theme.palette.action.hoverOpacity
                ),
              },
            };
          }

          if (variant === 'roundedText') {
            return {
              ...style,
              color: palette == null ? 'inherit' : palette.main,
              borderRadius: defaultStyles.buttonBorderRadius,
              backgroundColor: 'transparent',
              border: '1px solid transparent',
              '&:hover': {
                backgroundColor: alpha(
                  palette == null ? 'inherit' : palette.main,
                  theme.palette.action.hoverOpacity
                ),
                border: `1px solid ${theme.palette.action.hoverOpacity}`,
              },
            };
          }

          if (variant === 'unclickable') {
            return {
              ...style,
              color: palette == null ? 'inherit' : palette.main,
              backgroundColor: theme.palette.common.white,
              border: `1px solid ${palette == null ? 'inherit' : palette.main}`,
            };
          }
        },
      },
      variants: [
        {
          props: { variant: 'unclickable' },
          style: {
            pointerEvents: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            disableFocusRipple: true,
            disableRipple: true,
            disableTouchRipple: true,
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: { color: 'white' },
        },
        {
          props: { variant: 'lightContained', color: 'success' },
          style: {
            borderRadius: defaultStyles.buttonBorderRadius,
            color: themeWithPalette.palette.getContrastText(vdxGreen[400]),
            backgroundColor: vdxGreen[400],
            '&:hover': {
              backgroundColor: vdxGreen[600],
            },
          },
        },
        {
          props: { variant: 'lightContained', color: 'error' },
          style: {
            borderRadius: defaultStyles.buttonBorderRadius,
            color: themeWithPalette.palette.getContrastText(vdxRed[400]),
            backgroundColor: vdxRed[400],
            '&:hover': {
              backgroundColor: vdxRed[600],
            },
          },
        },
        {
          props: { variant: 'lightContained', color: 'warning' },
          style: {
            borderRadius: defaultStyles.buttonBorderRadius,
            color: themeWithPalette.palette.getContrastText(vdxYellow[400]),
            backgroundColor: vdxYellow[400],
            '&:hover': {
              backgroundColor: vdxYellow[600],
            },
          },
        },
        {
          props: { variant: 'lightContained', color: 'info' },
          style: {
            borderRadius: defaultStyles.buttonBorderRadius,
            color: themeWithPalette.palette.getContrastText(vdxTeal[400]),
            backgroundColor: vdxTeal[400],
            '&:hover': {
              backgroundColor: vdxTeal[600],
            },
          },
        },
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: themeWithPalette.palette.getContrastText(vdxGray[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: themeWithPalette.palette.text.primary,
            borderColor: 'rgba(0, 0, 0, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${themeWithPalette.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              backgroundColor: alpha(
                themeWithPalette.palette.text.primary,
                themeWithPalette.palette.action.hoverOpacity
              ),
            },
          },
        },
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            color: themeWithPalette.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                themeWithPalette.palette.text.primary,
                themeWithPalette.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'tabText' },
          style: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
            fontWeight: 500,
          },
        },
      ],
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: themeWithPalette.spacing(1),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-colorWhite': {
            '&:hover': {
              color: vdxGray[200],
            },
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      lgPlus: customBreakpoints.lgPlus,
      xl: 1536,
      xxl: customBreakpoints.xxl,
    },
  },
});

// #region spacing utilities
export const spacing = {
  tight: 1,
  normal: 2,
  loose: 4,
} as const;
// endregion

// #region interfaces
interface VendeluxThemeExtensions {
  vdxColors: {
    vdxGray: typeof vdxGray;
    vdxTeal: typeof vdxTeal;
    vdxRed: typeof vdxRed;
    vdxYellow: typeof vdxYellow;
    vdxGreen: typeof vdxGreen;
    vdxPink: typeof vdxPink;
    vdxPurple: typeof vdxPurple;
    vdxBlue: typeof vdxBlue;
  };
}
interface CustomChipProps extends ChipProps {
  ownerState: {
    variant: 'filled' | 'outlined';
  };
}
interface CustomTabProps extends TabProps {
  ownerState: {
    selected: boolean;
  };
}
interface CustomButtonProps extends ButtonProps {
  ownerState: {
    variant:
      | 'rounded'
      | 'roundedOutlined'
      | 'lightContained'
      | 'roundedText'
      | 'unclickable';
    disabled: boolean;
    color: ButtonProps['color'];
  };
}

// #endregion

// #region Typescript Augmentation

declare module '@mui/material/styles' {
  // noinspection JSUnusedGlobalSymbols
  interface Theme extends VendeluxThemeExtensions {}
  // noinspection JSUnusedGlobalSymbols
  interface ThemeOptions extends Partial<VendeluxThemeExtensions> {}
  interface BreakpointOverrides {
    lgPlus: true;
    xxl: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}
declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    tabText: true;
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xsmall: true;
  }
  interface SvgIconPropsColorOverrides {
    grey: true;
    white: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
  interface ButtonPropsVariantOverrides {
    lightContained: true;
    rounded: true;
    roundedOutlined: true;
    roundedText: true;
    unclickable: true;
  }
  interface ButtonPropsSizeOverrides {
    xlarge: true;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    pink: true;
    purple: true;
    lightblue: true;
  }
}
declare module '@mui/material' {
  interface Color {
    main: string;
    light: string;
    dark: string;
    contrastText: string;
    borderGrey?: string;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    pink: Palette['primary'];
    purple: Palette['primary'];
    white: Palette['primary'];
    lightblue: Palette['primary'];
  }

  interface PaletteOptions {
    pink?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    lightblue?: PaletteOptions['primary'];
  }
}

declare module '@mui/x-data-grid' {
  interface NoResultsOverlayPropsOverrides {
    text: string;
  }
}

// override CircularProgressPropsColorOverrides for color white
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true;
  }
}

// #endregion
